import {env} from '../../../environment'

export const fetchRedirect = (options: any) => {
  const url =
    env.getServiceHost() +
    'redirect/redirect?fallback=0&path=' +
    encodeURIComponent(options.path) +
    '&host=' +
    encodeURIComponent(env.getBaseUrl()) +
    '&search=' +
    encodeURIComponent(options.search)

  return fetch(url).then(response => response.json())
}
